export enum AcuityAppointmentTag {
  MemberAppScheduleLink = 'source:member-app:schedule-link',
  MemberAppSelfSchedule = 'source:member-app:self-schedule',
  MemberAppReschedule = 'source:member-app:reschedule',
}

export enum Location {
  InPerson = 'IP',
  Online = 'OL',
}

export enum Specialty {
  Medical = 'MD',
  Nutritional = 'NS',
  Exercise = 'ES',
  Metabolism = 'DIA',
}

export enum ScheduleRestrictionRuleCode {
  WeeklyNSEs = 'weekly-ns-es',
  WeeklyGC = 'weekly-gc',
  FollowUpMD = 'followup-md',
  FollowUpOther = 'followup-other',
}

type Availability = {
  provider: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    calendarId: number;
    timezone: string;
  };
  appointmentType: {
    id: number;
    officialName: string;
  };
};

export type MonthlyAvailability = Availability & {
  dates: {
    allowed: boolean;
    date: string;
    issues: ScheduleRestrictionRuleCode[];
  }[];
};

export type DailyAvailability = Availability & {
  times: {
    allowed: boolean;
    time: string;
    slotsAvailable: number;
  }[];
};

export type AcuityAppointment = {
  id: number;
  category: string | null;
  duration: string;
  dateCreated: string;
  datetimeCreated: string;
  datetime: string;
  endTime: string;
  timezone: string;
  provider: {
    providerId: string;
    providerFirstName: string;
    providerLastName: string;
  };
};

export type DecryptedSchedulingLink = {
  authToken: string;
  appointmentTypeId: number;
};

export type DecryptedSchedulingLinkResponse = {
  linkInfo: DecryptedSchedulingLink;
};
export enum AcuityCancelAppointmentSource {
  MemberAppManageAppointment = 'source:member-app:manage-appointment',
  MemberWebAppManageAppointment = 'source:member-web-app:manage-appointment',
  ProviderWebAppAsProviderManageAppointment = 'source:provider-web-app:manage-appointment',
}

export type ScheduledAppointment = {
  date: string;
  endTime: string;
  datetime: string;
  timezone: string;
  location: string;
  calendar: string;
  appointmentId: number;
  email?: string;
  time?: string;
  dateCreated?: string;
  datetimeCreated?: string;
  type?: string;
  appointmentTypeID?: number;
  category?: string;
  duration?: string;
  canceled?: boolean;
};

export type ScheduledAppointmentsPerSpecialty = {
  [specialty: string]: ScheduledAppointment[];
};

type Provider = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  calendarId: number;
};

export type AppointmentTypeSlotWithProvider = {
  provider: Provider;
  time: string;
  slotsAvailable: number;
};

export type AppointmentTypeWithAvailableSlots = {
  active: boolean;
  calendarIDs: number[];
  code: string;
  countAsChargeableVisit: boolean;
  description: string;
  duration: number;
  id: number;
  image: string;
  name: string;
  tags: string[];
  nextSlotAt: string;
  providers: Provider[];
  slots: AppointmentTypeSlotWithProvider[];
};

export type ScheduledMeeting = {
  id: number;
  meetingId: string;
  source: 'core' | string;
  externalSchedulingId: number | null;
  provider: 'chime' | string;
  metadata: {
    appointmentType: string;
    concatenatedPipelineId?: string;
    endAt: string;
    environment?: string;
    internalPatientUuid: string;
    internalProviderUuid: string;
    pipelineId?: string;
    startAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};
