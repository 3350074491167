import { CORE_API_HOST_URL } from '../environment';
import HttpRequest from '../request';
import {
  CoreProviderConfig,
  PlaceOfService,
  PlaceOfServiceRecord,
  ProviderConfig,
  VideoCallStatusParams,
} from './types';

class CoreServiceClient extends HttpRequest {
  public async sendTelemedicinePlaceOfService(
    placeOfServiceRecord: PlaceOfServiceRecord
  ): Promise<number | undefined> {
    const { memberId, scheduleId, place, authToken } = placeOfServiceRecord;

    const response = await this.patch<PlaceOfService>({
      url: `/api/v2/patient/${memberId}/schedulings/${scheduleId}`,
      payload: { place_of_service: place },
      options: { authAs: 'header', token: authToken },
    });

    return response?.id;
  }

  public async setVideoCallStatusTo(statusParams: VideoCallStatusParams): Promise<void> {
    const { scheduleId, providerId, providerAuthToken, videoCallStatus } = statusParams;
    await this.patch({
      url: `/api/v2/providers/${providerId}/schedulings/${scheduleId}`,
      payload: {
        scheduling: {
          attendance_status: videoCallStatus,
        },
      },
      options: { authAs: 'header', token: providerAuthToken },
    });
  }

  public async getMyConfig(statusParams: {
    providerAuthToken: string;
    schedulingId?: number;
  }): Promise<ProviderConfig | null> {
    const { schedulingId, providerAuthToken } = statusParams;

    const response = await this.get<CoreProviderConfig>({
      url: `/api/v4/providers/me/config?scheduling_id=${schedulingId || ''}`,
      options: { authAs: 'header', token: providerAuthToken },
    });

    if (!response) {
      return null;
    }

    return {
      provider: {
        id: response.provider.id,
        email: response.provider.email,
        scribeConfig: {
          viewNotes: response.provider.scribe_config.view_notes,
          generateNotes: response.provider.scribe_config.generate_notes,
        },
      },
      scheduling: {
        viewRecording: response.scheduling.view_recording,
      },
    };
  }
}

const coreServiceClient = new CoreServiceClient(CORE_API_HOST_URL);

export default coreServiceClient;
