import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  LocalVideoTrack,
  Room,
  connect,
  createLocalAudioTrack,
  createLocalVideoTrack,
} from 'twilio-video';
import { ErrorTypes } from '../../config/constants';
import {
  ALLOW_CAMERA,
  ALLOW_MICROPHONE,
  ALLOW_SCREEN_SHARING,
  CAMERA_OR_MICROPHONE_IN_USE,
  COULD_NOT_SET_UP_THE_CALL,
  NO_ROOM_INFORMATION,
  SCREEN_SHARING_NOT_SUPPORTED,
} from '../../config/lang';
import { useFeatures } from '../../contexts/features/context';
import { VideoCallProvider, useVideoCall } from '../../contexts/videoCall/context';
import { VideoCallActions } from '../../contexts/videoCall/types';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import { Features } from '../../lib/features/client';
import { StorageKeys, getItem } from '../../lib/storage';
import MixpanelClient, {
  LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
  LegacyTrackingEvent,
} from '../../lib/tracking/mixpanel';
import videoServiceClient from '../../lib/video/client';
import { VideoRoomResponse } from '../../lib/video/types';
import Toast from '../Toast/Toast';
import CallEnded from './components/CallEnded';
import EnaraRoom from './components/EnaraRoom';
import GetReady from './components/GetReady';
import TelemedicineModal from './components/TelemedicineModal';
import './video-call.css';

type MainState = {
  data: VideoRoomResponse | null;
  identity: string;
  room: Room | null;
  loadingJoin: boolean;
  loadingPresent: boolean;
  loadingMode: boolean;
  showModalByClinic: boolean;
  showTelemedicineModal: boolean;
  callEnded: boolean;
};

const VideoCall: FC = () => {
  const [mainState, setMainState] = useState<MainState>({
    data: null,
    identity: '',
    room: null,
    loadingJoin: false,
    loadingPresent: false,
    loadingMode: false,
    showModalByClinic: false,
    showTelemedicineModal: false,
    callEnded: false,
  });

  const { videoToken } = useParams<{ videoToken: string }>();
  const [searchParams] = useSearchParams();
  const userUuid = searchParams.get('userUuid');

  const { featuresState } = useFeatures();
  const { dispatchVideoCall } = useVideoCall();

  const presenting = useRef(false);

  const joinRoom = useCallback(async () => {
    let videoTrack;
    let audioTrack;

    try {
      setMainState((prevState) => ({ ...prevState, loadingJoin: true }));

      try {
        videoTrack = await createLocalVideoTrack();
      } catch (error: any) {
        logErrorAndReportToHoneybadger({ error });

        if (error?.name === ErrorTypes.NotAllowedError) {
          throw new Error(ALLOW_CAMERA);
        }

        throw error;
      }

      try {
        audioTrack = await createLocalAudioTrack();
      } catch (error: any) {
        logErrorAndReportToHoneybadger({ error });

        if (error?.name === ErrorTypes.NotAllowedError) {
          throw new Error(ALLOW_MICROPHONE);
        }

        throw error;
      }

      if (!mainState.data) {
        Toast.warning(NO_ROOM_INFORMATION);
      }

      // Join the Room with the pre-acquired LocalTracks
      const room = await connect(mainState.data!.token, {
        name: mainState.data!.room,
        tracks: [videoTrack, audioTrack],
      });

      setMainState((prevState) => ({ ...prevState, room, presenting: false }));
    } catch (error: any) {
      logErrorAndReportToHoneybadger({ error });
      setMainState((prevState) => ({ ...prevState, loadingJoin: false }));

      if (videoTrack) {
        videoTrack.stop();
      }

      if (audioTrack) {
        audioTrack.stop();
      }

      if (error.name === ErrorTypes.NotReadableError) {
        Toast.error(CAMERA_OR_MICROPHONE_IN_USE);
      } else if (error.message) {
        Toast.error(error.message);
      }
    }
  }, [mainState.data]);

  const placeOfServiceWasRegistered = useCallback(
    (scheduleId: number) => {
      const scheduleIdRegistered = getItem(StorageKeys.TelemedicineSchedulingId) as number | null;

      if (scheduleIdRegistered) {
        if (scheduleIdRegistered !== scheduleId) {
          MixpanelClient.trackLegacyEventWithTag({
            tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
            eventName: LegacyTrackingEvent.TelemedicineOpen,
          });

          setMainState((prevState) => ({ ...prevState, showTelemedicineModal: true }));
        } else {
          joinRoom();
        }
      } else {
        MixpanelClient.trackLegacyEventWithTag({
          tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
          eventName: LegacyTrackingEvent.TelemedicineOpen,
        });

        setMainState((prevState) => ({ ...prevState, showTelemedicineModal: true }));
      }
    },
    [joinRoom]
  );

  const closeModal = useCallback(() => {
    setMainState((prevState) => ({ ...prevState, showTelemedicineModal: false }));
  }, []);

  const checkTelemedicineBeforeJoiningRoom = useCallback(() => {
    MixpanelClient.trackLegacyEventWithTag({
      tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
      eventName: LegacyTrackingEvent.VideoCallAppClickJoinCall,
    });

    if (mainState.showModalByClinic) {
      if (mainState.data?.identity_detail?.schedule_id) {
        placeOfServiceWasRegistered(mainState.data.identity_detail.schedule_id);
      } else {
        joinRoom();
      }
    } else {
      joinRoom();
    }
  }, [
    mainState.data?.identity_detail?.schedule_id,
    mainState.showModalByClinic,
    placeOfServiceWasRegistered,
    joinRoom,
  ]);

  const toggleMode = useCallback(async () => {
    setMainState((prevState) => ({ ...prevState, loadingMode: true }));

    if (!mainState || !mainState.room) {
      Toast.warning(NO_ROOM_INFORMATION);
    }

    try {
      let localVideoTrack;

      if (presenting.current) {
        // When sharing screen, this gets executed when you want to stop sharing screen
        try {
          localVideoTrack = await createLocalVideoTrack();

          MixpanelClient.trackLegacyEventWithTag({
            tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
            eventName: LegacyTrackingEvent.VideoCallAppProviderSwitchToCamera,
          });
        } catch (error: any) {
          logErrorAndReportToHoneybadger({ error });

          if (error.name === ErrorTypes.NotAllowedError) {
            throw new Error(ALLOW_CAMERA);
          }

          throw error;
        }
      } else {
        // When showing camera, this gets executed when you want to share screen
        let stream;

        try {
          stream = await navigator.mediaDevices.getDisplayMedia();
        } catch (error: any) {
          logErrorAndReportToHoneybadger({ error });

          if (error.name === ErrorTypes.TypeError) {
            throw new Error(SCREEN_SHARING_NOT_SUPPORTED);
          } else if (error.name === ErrorTypes.NotAllowedError) {
            throw new Error(ALLOW_SCREEN_SHARING);
          }

          throw error;
        }

        // Catches the stop sharing event and consider that as toggle
        stream.getTracks()[0].addEventListener('ended', () => setTimeout(toggleMode, 500));
        localVideoTrack = new LocalVideoTrack(stream.getTracks()[0]);

        MixpanelClient.trackLegacyEventWithTag({
          tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
          eventName: LegacyTrackingEvent.VideoCallAppProviderSwitchToShareScreen,
        });
      }

      const videoTracks = Array.from(mainState.room!.localParticipant.videoTracks.values()).map(
        (publication) => publication.track
      );

      for (const t of videoTracks) {
        t.stop();
      }

      mainState.room!.localParticipant.unpublishTracks(videoTracks);
      mainState.room!.localParticipant.publishTrack(localVideoTrack);

      setMainState((prevState) => ({
        ...prevState,
        loadingMode: false,
      }));
      presenting.current = !presenting.current;
    } catch (error: any) {
      logErrorAndReportToHoneybadger({ error });

      setMainState((prevState) => ({ ...prevState, loadingMode: false }));

      if (error.name === ErrorTypes.NotReadableError) {
        Toast.error(CAMERA_OR_MICROPHONE_IN_USE);
      } else if (error.message) {
        Toast.error(error.message);
      }
    }
  }, [mainState]);

  const leaveRoom = useCallback(() => {
    MixpanelClient.trackLegacyEventWithTag({
      tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
      eventName: LegacyTrackingEvent.VideoCallAppLeaveCall,
    });

    setMainState((prevState) => ({
      ...prevState,
      room: null,
      loadingJoin: false,
      loadingPresent: false,
      callEnded: true,
    }));
  }, []);

  useEffect(() => {
    if (userUuid) {
      MixpanelClient.identifyUser(userUuid);
    }

    (async () => {
      const memberId = searchParams.get('memberId');
      try {
        const data = await videoServiceClient.fetchVideoRoomWithToken({
          token: encodeURIComponent(videoToken!),
          memberId,
        });

        if (data) {
          dispatchVideoCall({ type: VideoCallActions.SetCallInfo, payload: data });

          setMainState((prevState) => ({ ...prevState, data }));

          MixpanelClient.registerUserMetaData({
            userType: data?.user_type,
            room: data?.room,
            identityDetail: data?.identity_detail,
          });

          MixpanelClient.trackLegacyEventWithTag({
            tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
            eventName: LegacyTrackingEvent.VideoCallAppOpen,
          });

          if (data.identity_detail && Object.keys(data.identity_detail).length > 0) {
            const { enabled, clinicIds } =
              featuresState[Features.MemberShowTelemedicineModalByClinic];
            const showTelemedicineModal =
              enabled && (!clinicIds || clinicIds.includes(data.identity_detail.clinic_id));

            if (showTelemedicineModal) {
              setMainState((prevState) => ({
                ...prevState,
                showModalByClinic: showTelemedicineModal,
              }));
            }
          }
        }
      } catch (error) {
        Toast.error(COULD_NOT_SET_UP_THE_CALL);

        logErrorAndReportToHoneybadger({ error });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Enara: Appointments</title>
      </Helmet>
      <div className='app'>
        {mainState.callEnded ? (
          <CallEnded />
        ) : (
          <>
            <TelemedicineModal
              modalVisibility={mainState.showTelemedicineModal}
              meetingData={mainState.data}
              setModalVisibility={closeModal}
              onJoinRoom={joinRoom}
            />

            {mainState.room === null ? (
              <GetReady
                loadingJoin={mainState.loadingJoin}
                videoRoomInfoAlreadyObtained={mainState.data !== null}
                checkTelemedicineBeforeJoiningRoom={checkTelemedicineBeforeJoiningRoom}
              />
            ) : (
              <EnaraRoom
                data={mainState.data!}
                room={mainState.room}
                presenting={presenting.current}
                loadingMode={mainState.loadingMode}
                onLeaveRoom={leaveRoom}
                onToggle={toggleMode}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <VideoCallProvider>
    <VideoCall />
  </VideoCallProvider>
);
