import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faCalendar,
  faDesktop,
  faPhoneSlash,
  faUserCheck,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { LocalParticipant } from 'twilio-video';
import { useFeatures } from '../../../../../contexts/features/context';
import { useVideoCall } from '../../../../../contexts/videoCall/context';
import { VideoCallStatus } from '../../../../../lib/core/types';
import { Features } from '../../../../../lib/features/client';
import FeatureManager from '../../../../../lib/features/manager';
import MixpanelClient, { TrackingEvent } from '../../../../../lib/tracking/mixpanel';
import { UserType } from '../../../../../lib/video/types';
import AVControl from './AVControl';
import ToolBoxButton from './ToolBoxButton';
import './tool-box.css';

export type ClickOptionCallbackParams =
  | {
      action: ToolboxAction.LeaveRoom | ToolboxAction.ScreenSharing | ToolboxAction.ShowOutline;
    }
  | { action: ToolboxAction.VideoCallStatus; status: VideoCallStatus };

export enum ToolboxAction {
  ScreenSharing,
  LeaveRoom,
  VideoCallStatus,
  ShowOutline,
}

export type RefToolbox = {
  setMarkedAsAttended: () => void;
};

type Props = {
  userType: UserType;
  isPresenting: boolean;
  loadingMode: boolean;
  participant: LocalParticipant;
  onClickOption: (param: ClickOptionCallbackParams) => void;
};

const Toolbox: ForwardRefRenderFunction<RefToolbox, Props> = (
  { userType, isPresenting, loadingMode, participant, onClickOption },
  ref: ForwardedRef<RefToolbox>
) => {
  const [hasAttended, setHasAttended] = useState<boolean>(false);
  const [hasReschedule, setHasReschedule] = useState<boolean>(false);

  const { featuresState } = useFeatures();
  const {
    videoCallState: { callInfo },
  } = useVideoCall();

  useImperativeHandle(ref, () => ({
    setMarkedAsAttended: () => setHasAttended(true),
  }));

  const handleMarkAttended = () => {
    setHasAttended(!hasAttended);
    setHasReschedule(false);

    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Mark as Attended' },
    });

    onClickOption({ action: ToolboxAction.VideoCallStatus, status: VideoCallStatus.Attended });
  };

  const handleReschedule = () => {
    setHasReschedule(!hasReschedule);
    setHasAttended(false);

    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Mark as Rescheduled' },
    });

    onClickOption({ action: ToolboxAction.VideoCallStatus, status: VideoCallStatus.Rescheduled });
  };

  return (
    <div className='control'>
      <div className='control-inner-container'>
        <AVControl participant={participant} loadingMode={loadingMode} />
      </div>

      <div className='control-inner-container'>
        {userType === UserType.Provider && (
          <ToolBoxButton
            toolTip={isPresenting ? 'Stop Sharing' : 'Share Screen'}
            label={isPresenting ? 'Stop Sharing' : 'Share Screen'}
            onClick={() => onClickOption({ action: ToolboxAction.ScreenSharing })}
            loadingMode={loadingMode}
            icon={(isPresenting ? faVideo : faDesktop) as IconDefinition}
          />
        )}
        <ToolBoxButton
          toolTip='Leave call'
          label='Leave call'
          onClick={() => onClickOption({ action: ToolboxAction.LeaveRoom })}
          loadingMode={loadingMode}
          customStyle='tool-box-button-red'
          icon={faPhoneSlash as IconDefinition}
        />
      </div>

      {userType === UserType.Provider ? (
        <div className='control-inner-container'>
          <ToolBoxButton
            toolTip='Mark as Attended'
            label={`Mark as\nAttended`}
            onClick={handleMarkAttended}
            customStyle={hasAttended ? 'toolbox-button-green' : ''}
            loadingMode={loadingMode}
            icon={faUserCheck as IconDefinition}
          />

          <ToolBoxButton
            toolTip='Mark as Reschedule'
            label={`Mark as\nReschedule`}
            onClick={handleReschedule}
            loadingMode={loadingMode}
            customStyle={hasReschedule ? 'toolbox-button-green' : ''}
            icon={faCalendar as IconDefinition}
          />

          {FeatureManager.shouldRenderOutlineForAppointment({
            feature: featuresState[Features.MembersWebAppShowOutline],
            userType: callInfo.user_type,
            appointmentType: callInfo.provider?.appointment_type,
          }) && (
            <ToolBoxButton
              toolTip='Show Member Outline'
              label={`Show Outline`}
              onClick={() => onClickOption({ action: ToolboxAction.ShowOutline })}
              loadingMode={loadingMode}
              icon={faBook as IconDefinition}
            />
          )}
        </div>
      ) : (
        <div className='control-inner-container-fixed'> </div>
      )}
    </div>
  );
};

export default forwardRef(Toolbox);
